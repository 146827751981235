import React from 'react'
import Game from './components/Game'

function Casino() {
    return (
        <div style={{ backgroundRepeat:"no-repeat", backgroundSize:"100vw 100vh", position:"fixed",width:"100%", height:"100%"}}>
            <img alt="bgimgae" width="100%" height="100%" style={{position:"fixed", top:"0", left:"0", bottom:"0", width:"100vw", height:"100vh", zIndex:-70,maxWidth:"initial"}} src="/bg3.png" />
            <Game />
        </div>
    )
}

export default Casino

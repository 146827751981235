import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://exchange.billhwangdefi.co/#/swap?outputCurrency=0x1f36f067f4e425994763da9ee5bcc76f154c82d1',
      },
      {
        label: 'Liquidity',
        href: 'https://exchange.billhwangdefi.co/#/pool',
      },
    ],
  },
  {
    label: 'Insider Trading',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'FOMO',
    icon: 'PoolIcon',
    href: '/nests',
  },
  {
    label: 'Casino',
    icon: 'PoolIcon',
    href: '/casino',
  },


  {
    label: 'BillVaults',
    icon: 'PoolIcon',
    href: 'https://vaults.billhwangdefi.co',
  },
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: '/lottery',
  // },


  {
    label: 'IMO',
    icon: 'GooseIcon',
    href: '/imo',
  },
  // {
  //   label: 'NFT',
  //   icon: 'NftIcon',
  //   href: '/nft',
  // },
  // {
  //   label: 'Info',
  //   icon: 'InfoIcon',
  //   items: [
  //     {
  //       label: 'PancakeSwap',
  //       href: 'https://pancakeswap.info/token/0xE38676028803b9F6c7ef36642d68bAf6F0D4f846',
  //     },
      
  //     {
  //       label: 'AstroTools',
  //       href: 'https://app.astrotools.io/pancake-pair-explorer/0xE38676028803b9F6c7ef36642d68bAf6F0D4f846',
  //     },
  //   ],
  // },

  {
    label: 'Audit by CertiK',
    icon: 'AuditIcon',
    href: 'https://www.certik.org/projects/billhwangfinance',
  },
  {
    label: 'Audit By Solidity',
    icon: 'AuditIcon',
    href: 'https://solidity.finance/audits/BillHwang/',
  },
 
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Github',
        href: 'https://github.com/BillHwang/',
      },
      {
        label:"Blog",
        href:"https://medium.com/@billhwangfinance"
      },
      {
        label:"Documentation",
        href:"https://billhwang-defi.gitbook.io/billhwang-defi/"
      }
     
    ],
  },
  
  // {
  //   label: 'Audit',
  //   icon: 'AuditIcon',
  //   href: 'https://www.goosedefi.com/files/hackenAudit.pdf',
  // },
  // {
  //   label: 'Audit by CertiK',
  //   icon: 'AuditIcon',
  //   href: 'https://certik.org/projects/goose-finance',
  // },
]

export default config

import React, { useState, useCallback, useEffect } from 'react'

import {  Button, Card } from '@pancakeswap-libs/uikit'
import { useCasinoApprove } from 'hooks/useApprove'
import { useWallet } from '@binance-chain/bsc-use-wallet'

import './Css/ButtonWrapper.css'
import './Css/loader.css'

import FlexLayout from 'components/layout/Flex'
import { useCasino } from 'hooks/useContract'
import { useCasinoAllowance } from 'hooks/useAllowance'
import { fontSize } from 'styled-system'
// import {CardAudio} from './Css/card.mp3';

const Game = () => {
  const [main, setmain] = useState(true)
  const [value, setvalue] = useState(0)
  const [billFilpped, setbillFilpped] = useState(false)
  const [myFlipped, setmyFlipped] = useState(false)
  const [billNumber, setBillNumber] = useState(0)
  const [playerNumber, setPlayerNumber] = useState(0)
  const [winModal, setwinModal] = useState(false)
  const [requestedApproval, setRequestedApproval] = useState(false)
  const { onApprove } = useCasinoApprove()
  const allowance = useCasinoAllowance()
  const casinoContract = useCasino()
  const { account } = useWallet()
  
  const [selectedButton, setSelectedButton] = useState("")

  const [allowanceLoaded,setAllowanceLoaded] = useState(false)
  const getRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min)

  }

  const Spinner = ()=>{
    return <div className="loader">Loading...</div>
  }


  useEffect(()=>{
    if(allowance.toNumber()){
      setAllowanceLoaded(true)
    }
  },[allowance])

  const handleApprove = useCallback(async () => {
    let respo = false
    try {
      respo = await onApprove()
      return respo
    } catch (e) {
      console.error(e)
    }

    return respo
  }, [onApprove])

  // const buyTicket = async(amount)=>{

  // }

  const onGameStart=async(price)=> {
    // ButtonClick()
    // setvalue(e)

    setSelectedButton(price.toString())
    const audio = new Audio('https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/click.mp3')
    audio.play()

    const amount = `${price}000000000000000000`;
    
   
   
    try {
      const resp =    await casinoContract.methods.playGame(amount).send({from:account});
      const event = resp.events.onGamePlayed.returnValues;
  
      console.log("ressss",resp)
      setBillNumber(parseInt(event.billNumber))
      setmain(false)
      setPlayerNumber(parseInt(event.userNumber))
    }
    catch(err) {
      setSelectedButton("")

    }

  }
  const ButtonClick = () => {
    const audio = new Audio('https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/click.mp3')
    audio.play()
    // <div>
    //     <audio src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/click.mp3" autoPlay /><track default kind="captions" />
    // </div>
  }
  function newGame() {
    setSelectedButton("")
    ButtonClick()
    const audio = new Audio('https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/click.mp3')
    audio.play()
    setmyFlipped(false)
    setbillFilpped(false)
    setmain(true)
    setvalue(0)
    setwinModal(false)
  }

  const renderCasinoTicketButtons = () => {
    if (!allowance.toNumber() && !allowanceLoaded) {
      return (
        <>
          <Button 
                    style={{ fontSize: 36, borderRadius: 36, }}
            fullWidth
          disabled={requestedApproval} onClick={handleApprove}>
            Approve BILL
          </Button>
        </>
      )
    }
    return (
      <>
        <Button
          className="button -dark center"
          style={{ fontSize: 36, borderRadius: 36 }}
          onClick={() => {
            onGameStart(50)
          }}
        >
          {selectedButton === "50" ? <Spinner /> : '50'}
        </Button>

        <Button
          className="button -green center"
          style={{ fontSize: 36, borderRadius: 36 }}
          onClick={() => {
            onGameStart(250)
          }}
        >
          {selectedButton === "250" ? <Spinner /> : '250'}
        </Button>

        <Button
          className="button -blue center"
          style={{ fontSize: 36, borderRadius: 36 }}
          onClick={() => onGameStart(1000)}
        >
          {selectedButton === "1000" ? <Spinner /> : '1000'}
        </Button>

        <Button
          className="button -sun center"
          style={{ fontSize: 36, borderRadius: 36 }}
          onClick={() => onGameStart(5000)}
        >
          {selectedButton === "5000" ? <Spinner /> : '5000'}
        </Button>

        <Button
          className="button -alge center"
          style={{ fontSize: 36, borderRadius: 36 }}
          onClick={() => onGameStart(10000)}
        >
          {selectedButton === "10000" ? <Spinner /> : '10000'}
        </Button>

        <Button
          className="button -flower center"
          style={{ fontSize: 36, borderRadius: 36 }}
          onClick={() => onGameStart(1000000)}
        >
          {selectedButton === "1000000" ? <Spinner /> : '1 Million'}
        </Button>
      </>
    )
  }

  function winGame(name) {
    if (name !== 'myCard') {
      const audio = new Audio('https://safyfinance.s3.amazonaws.com/card.mp3')
      audio.play()
      console.log('in if')
      setbillFilpped(true)
      if (myFlipped) {
        setTimeout(() => {
          setwinModal(true)
        }, 2000)
      }
    }
    // if (myFlipped && !billFilpped) {
    //     const audio = new Audio('https://safyfinance.s3.amazonaws.com/card.mp3');
    //     audio.play();
    //     setbillFilpped(true);
    //     console.log(main, "main");

    //     setTimeout(() => {
    //         setwinModal(true);
    //     }, 2000)
    //     // const audio = new Audio('https://safyfinance.s3.amazonaws.com/card.mp3');
    //     // audio.play();
    //     // CardAudio();
    // }
    // if (!myFlipped && billFilpped) {
    //     const audio = new Audio('https://safyfinance.s3.amazonaws.com/card.mp3');
    //     audio.play();
    //     setmyFlipped(true)
    //     console.log(main, "main");
    //     setTimeout(() => {
    //         setwinModal(true);
    //     }, 2000)

    //     // CardAudio();
    // }
    else {
      const audio = new Audio('https://safyfinance.s3.amazonaws.com/card.mp3')
      audio.play()
      setmyFlipped(true)
      if (billFilpped) {
        setTimeout(() => {
          setwinModal(true)
        }, 2000)
      }
      // CardAudio();
    }
  }

  return (
    <div>
      <div className="table2 center1" style={{ marginTop: 20 }}>
        <div className="monitor-wrapper center1">
          <div className="monitor center1">
            <p className="para">Welcome to Bill Casino. Wall Street Games</p>
          </div>
        </div>
      </div>
      <div style={{ width: '100%', margin: 'auto', paddingTop: '5%', marginTop: 10 }}>
        <div className="wrapper">
          <div className="card" />
          <div className="card" />
          <div className="card" />
          <div className="card" />
          <div className="card" />
          {/* <div className="card" /> */}
        </div>
        <div className="main" id="start" style={{ paddingTop: '15px' }}>
          {main && (
            <div className="loading-text">
              <span className="loading-text-words">W</span>
              <span className="loading-text-words">A</span>
              <span className="loading-text-words">L</span>
              <span className="loading-text-words">L</span>
              <span className="loading-text-words">S</span>
              <span className="loading-text-words">T</span>
              <span className="loading-text-words">R</span>
              <span className="loading-text-words">E</span>
              <span className="loading-text-words">E</span>
              <span className="loading-text-words">T</span>
              <span className="loading-text-words">G</span>
              <span className="loading-text-words">A</span>
              <span className="loading-text-words">M</span>
              <span className="loading-text-words">E</span>
              <span className="loading-text-words">S</span>
            </div>
          )}
          {main && <div className="button-container">{renderCasinoTicketButtons()}</div>}
        
          {!main && !winModal && (
            <FlexLayout style={{ marginTop: '20px' }}>
              <Card className={billFilpped ? 'filppedCard card3 flipped' : 'card3'} onClick={() => winGame('billCard')}>
                <div className="front">
                  {billFilpped && (
                    <img
                      src={`/casino/${billNumber}/${getRandomNumber(1, 4)}.png`}
                      alt="cardImg"
                      style={{ position: 'absolute', width: '100%', left: '0', top: '0', bottom: '0', right: '0' }}
                    />
                  )}
                  {!billFilpped && <span className="txt anim-text-flow">Bill Card</span>}

                  {!billFilpped && <p className="paraGr">Click To Flip</p>}
                </div>
                <div className="back" />
              </Card>
              <Card
                className={myFlipped ? 'filppedCard card4 flipped' : 'card4'}
                onClick={() => {
                  winGame('myCard')
                }}
              >
                <div className="front">
                  {!myFlipped && <span className="txt anim-text-flow">Your Card</span>}

                  {myFlipped && (
                    <img
                      src={`/casino/${playerNumber}/${getRandomNumber(1, 4)}.png`}
                      alt="cardImg"
                      style={{ position: 'absolute', width: '100%', left: '0', top: '0', bottom: '0', right: '0' }}
                    />
                  )}

                  {!myFlipped && (
                    <p id="pi" className="paraGr">
                      Click To Flip
                    </p>
                  )}
                </div>
                <div className="back" />
              </Card>
            </FlexLayout>
          )}

          {winModal && (
            <div>
              {billNumber < playerNumber ? (
                <img alt="winning" style={{ display: 'block', margin: 'auto', width: '150px' }} src="/happy.gif" />
              ) : (
                <img alt="loosing" style={{ display: 'block', margin: 'auto', width: '150px' }} src="/sad.gif" />
              )}

              <div style={{ textAlign: 'center', fontSize: '1em', marginTop: '-30px' }}>
                <br />
                {billNumber < playerNumber ? (
                  <div className="loading-text" style={{ lineHeight: '88px' }}>
                    <span className="loading-text-words">Y</span>
                    <span className="loading-text-words">O</span>
                    <span className="loading-text-words">U</span>
                    <span className="loading-text-words">&nbsp; </span>
                    <span className="loading-text-words">W</span>
                    <span className="loading-text-words">I</span>
                    <span className="loading-text-words">N</span>
                  </div>
                ) : (
                  <div className="loading-text">
                    <span className="loading-text-words">Y</span>
                    <span className="loading-text-words">O</span>
                    <span className="loading-text-words">U</span>
                    <span className="loading-text-words">&nbsp; </span>
                    <span className="loading-text-words">L</span>
                    <span className="loading-text-words">O</span>
                    <span className="loading-text-words">S</span>
                    <span className="loading-text-words">T</span>
                  </div>
                )}
              </div>
              <Button
                className="button -dark center"
                style={{
                  width: '200px',
                  fontFamily: 'Bangers',
                  margin: 'auto',
                  marginTop: '-0px',
                  borderRadius: '36px',
                  letterSpacing: '3px',
                }}
                color="primary"
                onClick={() => newGame()}
              >
                New Game{' '}
              </Button>
            </div>
          )}
        </div>

        <div id="frame">
          <div id="table">
            <div className="card card--sample">
              <span className="card__symbol card__symbol--heart">♣</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Game

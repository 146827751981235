import { isToken } from 'typescript'
import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 7,
    risk:5,
    lpSymbol: 'BILL-BNB',
    lpAddresses: {
      97: '0x93dFbB3E3bC4b4b10574d908FE9598d38AA764e6',
      56: '0xbbA912e253FA8D593986aa7f7924016b644438c5',
    },
    tokenSymbol: 'BILL',
    tokenAddresses: {
      97: '0x93dFbB3E3bC4b4b10574d908FE9598d38AA764e6',
      56: '0x1f36f067f4e425994763da9ee5bcc76f154c82d1',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    title:"Credit Suisse"

  },


 
  {
    pid: 8,
    risk:5,
    lpSymbol: 'BILL-BUSD',
    lpAddresses: {
      97: '0x9ce40a056d3168d13b3af06366ef2666e1b1025d',
      56: '0x362316b94AEc405B8fFF2446FBeBe07e59dCAd0f',
    },
    tokenSymbol: 'BILL',
    tokenAddresses: {
      97: '0x93dFbB3E3bC4b4b10574d908FE9598d38AA764e6',
      56: '0x1f36f067f4e425994763da9ee5bcc76f154c82d1',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    title:"Nomura"
  },

  {
    pid: 9,
    risk:5,
    lpSymbol: 'BILL-USDT',
    lpAddresses: {
      97: '0x11b9a40538544cc00b31803a34dd619a2be6586d',
      56: '0x63c0556d537038efC5a07Fd7178F3838B1E34E8A',
    },
    tokenSymbol: 'BILL',
    tokenAddresses: {
      97: '0x4998c1031e1c9cd49fe31f6786a0e6090fda95ea',
      56: '0x1f36f067f4e425994763da9ee5bcc76f154c82d1',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.ust,
    title:"Goldman Sachs"
  },


  {
    pid: 11,
    risk:5,
    lpSymbol: 'BILL-DOT',
    lpAddresses: {
      97: '0x11b9a40538544cc00b31803a34dd619a2be6586d',
      56: '0x78a990b0ffb1dde339d2b2af38bdbae2b4648e0e',
    },
    tokenSymbol: 'BILL',
    tokenAddresses: {
      97: '0x4998c1031e1c9cd49fe31f6786a0e6090fda95ea',
      56: contracts.cake[56],
    },
    quoteTokenSymbol: QuoteToken.DOT,
    quoteTokenAdresses: contracts.cake,
    title:"Morgan Stanley"
  },



  {
    pid: 4,
    risk:5,
    lpSymbol: 'USDT-BUSD',
    lpAddresses: {
      97: '0x11b9a40538544cc00b31803a34dd619a2be6586d',
      56: '0x7EFaEf62fDdCCa950418312c6C91Aef321375A00',
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      97: '0x4998c1031e1c9cd49fe31f6786a0e6090fda95ea',
      56: '0x55d398326f99059ff775485246999027b3197955',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    title:"UBS"
  },




  {
    pid: 5,
    risk:5,
    lpSymbol: 'BNB-BUSD',
    lpAddresses: {
      97: '0x5151D1Fd6aCF781144175383C65744Db0c5A28a9',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    tokenSymbol: 'BUSD',
    tokenAddresses: {
      97: '0xd0b4c1b5d59aa41e522699b6e469704916faed95',
      56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    title:"",
  },



  {
    pid: 6,
    risk:5,
    lpSymbol: 'BILL',
    lpAddresses: {
      97: '0x5151D1Fd6aCF781144175383C65744Db0c5A28a9',
      56: '0x362316b94AEc405B8fFF2446FBeBe07e59dCAd0f',
    },
    tokenSymbol: 'BILL',
    tokenAddresses: {
      97: '0xd0b4c1b5d59aa41e522699b6e469704916faed95',
      56: '0x1f36f067f4e425994763da9ee5bcc76f154c82d1',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    title:"Archegos",
    isTokenOnly:true
  },






]

export default farms
